import React, {Component} from "react";
import styles from "./Footer01.module.scss"

class Footer01 extends Component {
    render() {
        return (
            <div className={styles.container}>
                Masjid Dashboard
            </div>
        );
    }
}


export default Footer01;