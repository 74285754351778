import React, {Component} from "react";

class PageNotFound extends Component {
    render() {
        return(
            <div>
                <h3>404</h3>
                Page not found
            </div>
        );
    }
}

export default PageNotFound;